import { Link, useLocation } from "react-router-dom";
import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { Button } from "ui/Button/Button";
import { toast } from "ui/Toaster/Toaster";
import { ViewFinder } from "shared/ViewFinder";

export const EventQRPage = () => {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation as
    | Location
    | undefined;

  const onRead = (text: string) => {
    if (!text.startsWith("http")) {
      toast.error("Kod QR zawiera niepoprawny link");
    }
    const url = new URL(text);
    url.searchParams.set("isFromEventQRPage", "true");
    window.location.href = url.toString();
  };

  const handleResult = (qrCodes: IDetectedBarcode[]) => {
    const [qrCode] = qrCodes;
    if (!!qrCode) {
      onRead(qrCode.rawValue);
    }
  };

  const handleError = (error: unknown) => {
    if (error) {
      console.log(error);
      toast.error(error instanceof Error ? error.message : "Błąd skanowania");
    }
  };

  return (
    <>
      <div className="">
        {previousLocation ? (
          <Link to={previousLocation} className="m-auto">
            <Button
              variant="text"
              size="small"
              hasPadding={false}
              className="mb-4"
            >
              Strona startowa
            </Button>
          </Link>
        ) : null}

        <p className="">
          Zeskanuj kod QR wydarzenia wysłany na Twój adres e-mail:
        </p>
        <Scanner
          classNames={{ container: "mt-4" }}
          allowMultiple={true}
          components={{ audio: false, finder: false }}
          onScan={handleResult}
          onError={handleError}
        >
          <ViewFinder />
        </Scanner>
      </div>
    </>
  );
};
