import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { toast } from "ui/Toaster/Toaster";
import { Loader } from "ui/Loader/Loader";
import { ViewFinder } from "shared/ViewFinder";

type Props = {
  onRead: (code: string) => void;
  className?: string;
  isCheckingTicketOnline?: boolean;
};
export const QRCameraReader = ({
  className,
  onRead,
  isCheckingTicketOnline = false,
}: Props) => {
  const handleResult = (qrCodes: IDetectedBarcode[]) => {
    const [qrcode] = qrCodes;
    if (!!qrcode) {
      onRead(qrcode.rawValue);
    }
  };

  const handleError = (error: unknown) => {
    if (error) {
      console.log(error);
      toast.error(error instanceof Error ? error.message : "Błąd skanowania");
    }
  };

  return (
    <>
      {isCheckingTicketOnline ? <Loader /> : null}
      <Scanner
        classNames={{ container: className }}
        allowMultiple={true}
        components={{ audio: false, finder: false }}
        onScan={handleResult}
        onError={handleError}
      >
        <ViewFinder />
      </Scanner>
    </>
  );
};
