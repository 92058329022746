/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateTicket = /* GraphQL */ `subscription OnCreateTicket($filter: ModelSubscriptionTicketFilterInput) {
  onCreateTicket(filter: $filter) {
    id
    eventId
    ticketId
    metadata
    validated
    active
    log
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTicketSubscriptionVariables,
  APITypes.OnCreateTicketSubscription
>;
export const onUpdateTicket = /* GraphQL */ `subscription OnUpdateTicket($filter: ModelSubscriptionTicketFilterInput) {
  onUpdateTicket(filter: $filter) {
    id
    eventId
    ticketId
    metadata
    validated
    active
    log
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTicketSubscriptionVariables,
  APITypes.OnUpdateTicketSubscription
>;
export const onDeleteTicket = /* GraphQL */ `subscription OnDeleteTicket($filter: ModelSubscriptionTicketFilterInput) {
  onDeleteTicket(filter: $filter) {
    id
    eventId
    ticketId
    metadata
    validated
    active
    log
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTicketSubscriptionVariables,
  APITypes.OnDeleteTicketSubscription
>;
export const onCreateCommand = /* GraphQL */ `subscription OnCreateCommand($filter: ModelSubscriptionCommandFilterInput) {
  onCreateCommand(filter: $filter) {
    id
    scannerId
    type
    timestamp
    data
    response
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommandSubscriptionVariables,
  APITypes.OnCreateCommandSubscription
>;
export const onUpdateCommand = /* GraphQL */ `subscription OnUpdateCommand($filter: ModelSubscriptionCommandFilterInput) {
  onUpdateCommand(filter: $filter) {
    id
    scannerId
    type
    timestamp
    data
    response
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommandSubscriptionVariables,
  APITypes.OnUpdateCommandSubscription
>;
export const onDeleteCommand = /* GraphQL */ `subscription OnDeleteCommand($filter: ModelSubscriptionCommandFilterInput) {
  onDeleteCommand(filter: $filter) {
    id
    scannerId
    type
    timestamp
    data
    response
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommandSubscriptionVariables,
  APITypes.OnDeleteCommandSubscription
>;
