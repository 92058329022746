/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEvent = /* GraphQL */ `query GetEvent($eventId: String) {
  getEvent(eventId: $eventId) {
    id
    name
    publicKey
    scannerSettings {
      baseUrl
      picture
      primaryColor
      secondaryColor
      logo
      __typename
    }
    status
    description
    dates
    category
    types
    tags
    restrictions
    bucket
    tickets {
      isTicketResselingEnabled
      tickets {
        id
        name
        description
        isSinglePoolTicket
        ticketPools {
          id
          name
          price
          salesDates {
            start
            end
            hasStartDate
            hasEndDate
            __typename
          }
          hasSalesDates
          __typename
        }
        __typename
      }
      __typename
    }
    publishTime
    venues {
      id
      slug
      name
      address
      geohash
      city
      tags
      description
      bucket
      __typename
    }
    artists {
      id
      slug
      name
      geohash
      description
      tags
      bucket
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const getTicket = /* GraphQL */ `query GetTicket($id: ID!, $eventId: ID!) {
  getTicket(id: $id, eventId: $eventId) {
    id
    eventId
    ticketId
    metadata
    validated
    active
    log
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTicketQueryVariables, APITypes.GetTicketQuery>;
export const listTickets = /* GraphQL */ `query ListTickets(
  $id: ID
  $eventId: ModelIDKeyConditionInput
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTickets(
    id: $id
    eventId: $eventId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTicketsQueryVariables,
  APITypes.ListTicketsQuery
>;
export const syncTickets = /* GraphQL */ `query SyncTickets(
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTickets(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTicketsQueryVariables,
  APITypes.SyncTicketsQuery
>;
export const getCommand = /* GraphQL */ `query GetCommand($id: ID!) {
  getCommand(id: $id) {
    id
    scannerId
    type
    timestamp
    data
    response
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommandQueryVariables,
  APITypes.GetCommandQuery
>;
export const listCommands = /* GraphQL */ `query ListCommands(
  $id: ID
  $filter: ModelCommandFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCommands(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommandsQueryVariables,
  APITypes.ListCommandsQuery
>;
export const syncCommands = /* GraphQL */ `query SyncCommands(
  $filter: ModelCommandFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCommands(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCommandsQueryVariables,
  APITypes.SyncCommandsQuery
>;
