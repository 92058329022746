/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const ticketLog = /* GraphQL */ `mutation TicketLog($input: TicketLogInput!) {
  ticketLog(input: $input)
}
` as GeneratedMutation<
  APITypes.TicketLogMutationVariables,
  APITypes.TicketLogMutation
>;
export const validateTicket = /* GraphQL */ `mutation ValidateTicket($input: ValidateTicketInput!) {
  validateTicket(input: $input) {
    status
    ticket {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ValidateTicketMutationVariables,
  APITypes.ValidateTicketMutation
>;
export const sendCommand = /* GraphQL */ `mutation SendCommand($input: SendCommandInput!) {
  sendCommand(input: $input)
}
` as GeneratedMutation<
  APITypes.SendCommandMutationVariables,
  APITypes.SendCommandMutation
>;
export const updateCommandResponse = /* GraphQL */ `mutation UpdateCommandResponse($input: UpdateCommandInput!) {
  updateCommandResponse(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateCommandResponseMutationVariables,
  APITypes.UpdateCommandResponseMutation
>;
export const deleteCommand = /* GraphQL */ `mutation DeleteCommand(
  $input: DeleteCommandInput!
  $condition: ModelCommandConditionInput
) {
  deleteCommand(input: $input, condition: $condition) {
    id
    scannerId
    type
    timestamp
    data
    response
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommandMutationVariables,
  APITypes.DeleteCommandMutation
>;
export const createTicket = /* GraphQL */ `mutation CreateTicket(
  $input: CreateTicketInput!
  $condition: ModelTicketConditionInput
) {
  createTicket(input: $input, condition: $condition) {
    id
    eventId
    ticketId
    metadata
    validated
    active
    log
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTicketMutationVariables,
  APITypes.CreateTicketMutation
>;
export const updateTicket = /* GraphQL */ `mutation UpdateTicket(
  $input: UpdateTicketInput!
  $condition: ModelTicketConditionInput
) {
  updateTicket(input: $input, condition: $condition) {
    id
    eventId
    ticketId
    metadata
    validated
    active
    log
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTicketMutationVariables,
  APITypes.UpdateTicketMutation
>;
export const deleteTicket = /* GraphQL */ `mutation DeleteTicket(
  $input: DeleteTicketInput!
  $condition: ModelTicketConditionInput
) {
  deleteTicket(input: $input, condition: $condition) {
    id
    eventId
    ticketId
    metadata
    validated
    active
    log
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTicketMutationVariables,
  APITypes.DeleteTicketMutation
>;
export const createCommand = /* GraphQL */ `mutation CreateCommand(
  $input: CreateCommandInput!
  $condition: ModelCommandConditionInput
) {
  createCommand(input: $input, condition: $condition) {
    id
    scannerId
    type
    timestamp
    data
    response
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommandMutationVariables,
  APITypes.CreateCommandMutation
>;
export const updateCommand = /* GraphQL */ `mutation UpdateCommand(
  $input: UpdateCommandInput!
  $condition: ModelCommandConditionInput
) {
  updateCommand(input: $input, condition: $condition) {
    id
    scannerId
    type
    timestamp
    data
    response
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommandMutationVariables,
  APITypes.UpdateCommandMutation
>;
